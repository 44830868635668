import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import GetLink from "../functions/GetLink"
import PopularSearch from "../PopularSearch/PopularSearch"
import "./Footer.scss"
//import CookieConsent from "react-cookie-consent"
import CookieConsent from "../CookieConsent/CookieConsent"
import $ from "jquery"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import moment from "moment"
import armaLogoImage from "../../../src/images/arma-logo-dark-new.png"
const Footer = props => {
  const data = useStaticQuery(graphql`
    query getFooterData {
      glstrapi {
        globalConfig {
          Footer_Details {
            Footer_Contact_Number
            id
            Footer_Menus {
              Menu_Label
              Menu_External_Link
              Menu_Link {
                Primary_URL
                Secondary_URL
                id
                Label
              }
            }
          }
        }
      }
    }
  `)

  const footerData = data.glstrapi.globalConfig.Footer_Details
  // Cookie Trigger
  useEffect(() => {
    setTimeout(function () {
      if ($(".cookie-popup .CookieConsent").length > 0) {
        $(".footer-wrapper").addClass("with_cookie-popup")
      }
      $(".cookieok").click(function (event) {
        $(".acceptcookie").click()
      })
    }, 500)
  }, [])
  //
  var showbugherd = process.env.GATSBY_SHOW_BUGHERD
  return (
    <>
      {showbugherd == "true" ? (
        <Helmet>
          <script
            type="text/javascript"
            src="https://www.bugherd.com/sidebarv2.js?apikey=7rvb6zog5bvh5t9d1lhouw"
            async="true"
          ></script>
        </Helmet>
      ) : (
        ""
      )}
      <PopularSearch popularsearch={props.popularsearch} />
      <Container className="footer-wrapper">
        <CookieConsent />
        <div className="footer-container">
          <div className="footer-left">
            <div className="footer-socials">
              <i className="icon-insta-white"></i>
              <a
                href="https://www.instagram.com/sandfordsestateagents/?hl=en"
                className="label"
                target="_blank"
              >
                sandfordsestateagents
              </a>
            </div>
            <div className="footer-links">
              {footerData?.Footer_Menus &&
                footerData?.Footer_Menus.map((item, i) => (
                  <>
                    {item.Menu_External_Link ? (
                      <a
                        className="footer-link"
                        target={`_blank`}
                        href={item.Menu_External_Link}
                      >
                        {item.Menu_Label}
                      </a>
                    ) : (
                      <GetLink
                        label={item.Menu_Label}
                        link={item.Menu_Link}
                        className="footer-link"
                      />
                    )}
                    {i !== footerData?.Footer_Menus.length - 1 && (
                      <div className="vertical-divider" />
                    )}
                  </>
                ))}
              <div className="vertical-divider" />
              <a href="javascript:void(0);" id="open_preferences_center">
                Update cookies preferences
              </a>
            </div>
            <ul className="footer-copyright accreditations-content">
              <li className="tpo-logo-content accreditations-logo-content">
                <i className="icon-tpo-logo"></i>
              </li>
              <li className="arla-logo-content accreditations-logo-content">
                <i className="icon-arla-logo"></i>
              </li>
              <li className="arma-logo-content accreditations-logo-content">
                <img src={armaLogoImage} alt="Arma" />
              </li>
            </ul>
            <div className="footer-copyright">
              <p className="copyright">
                Copyright © Sandfords {moment(new Date()).format("YYYY")}. All
                rights reserved. Registered in England No. 02961709.
              </p>
              <p className="site-by">
                Site by
                <a href="https://starberry.tv/" target="_blank">
                  <i className="icon-sb-logo"></i>
                </a>
              </p>
            </div>
          </div>
          <div className="footer-right">
            <div className="contact-info">
              <h4 className="contact-label">contact us</h4>
              <a
                href={`tel:${footerData.Footer_Contact_Number}`}
                className="contact-number"
              >
                {footerData.Footer_Contact_Number}
              </a>
            </div>
          </div>
        </div>
      </Container>
      {/* <section className="cookie-popup">
        <CookieConsent
          location="bottom"
          buttonText="Accept Cookies"
          cookieName="CookiePolicy"
          buttonClasses="acceptcookie"
          overlay={false}
          // acceptOnScrollPercentage={1}
          //onAccept={()=> ClosePopup()}
        >
          <div className="topbar-block">
            <p>
              We have placed cookies on your device to help make this website
              better. By continuing, you agree to our{" "}
              <Link to="/cookie-policy/">Cookie Policy</Link>.{" "}
              <a href="javascript:void(0);" class="cookieok">
                Accept Cookies
              </a>
            </p>
            <i className="icon-close-dark"></i>
          </div>
        </CookieConsent>
      </section> */}
    </>
  )
}

export default Footer
